html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #FFF;
  font-family: "Raleway", sans-serif;
  font-weight: 200;
  // 400 regular
  // 700 bold
}

header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 350px;
  position: relative;
  text-align: center;

  .black_gradient {
    width: 100%;
    min-height: 100px;
    position: absolute;
    height: auto;
    background-image: url('./covers/black_gradient.png');
    background-repeat: repeat-x;
    background-position: center bottom;
    bottom: 0;
    left: 0;
  }
  .inner {
    position: relative;
    max-width: 900px;
    margin: 0 auto;
    padding: 24px 24px 0;
  }
  .resume {
    display: none;
    margin: 0 auto 15px;
  }
  a.resume, a.resume:link, a.resume:visited, a.resume:active {
    font-weight: 500;
    color: #FFF;
    text-decoration: none;
  }
  a.resume:hover {
    text-decoration: underline;
  }

  h1 {
    color: #FFF;
    text-align: left;
    font-size: 60px;
    line-height: 20px;
    font-weight: 200;
    margin: 0 0 20px;
    width: auto;
    float: left;
    text-shadow: 0 2px 0 #000, 0 2px 10px #000, 0 2px 8px #000, 0 2px 5px #000;
    a, a:link, a:active, a:visited {
      color: #FFF;
      text-decoration: none;
    }
  }

  &.cover_1 { background-image: url('./covers/australia_01@2x.jpg'); }
  &.cover_2 { background-image: url('./covers/australia_02@2x.jpg'); }
  &.cover_3 { background-image: url('./covers/greece_01@2x.jpg'); }
  &.cover_4 { background-image: url('./covers/morocco_01@2x.jpg'); }
  &.cover_5 { background-image: url('./covers/newzealand_01@2x.jpg'); }
  &.cover_6 { background-image: url('./covers/newzealand_02@2x.jpg'); }
  &.cover_7 { background-image: url('./covers/newzealand_03@2x.jpg'); }
  &.cover_8 { background-image: url('./covers/paris_01@2x.jpg'); }

  .links {
    float: right;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      margin-right: 4px;
      a {
        display: block;
        height: 32px;
        width: 32px;
        border: 1.5px solid #FFFFFF;
        border-radius: 50%;
        line-height: 28px;
        color: #FFF;
        text-align: center;
        box-sizing: border-box;
        &:hover {
          opacity: 0.4;
        }
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}

section#content {
  max-width: 900px;
  margin: 0 auto;
  color: #FFF;
  padding: 24px 24px 48px;
  text-align: center;
  position: relative;
  font-size: 17px;
  line-height: 140%;
  letter-spacing: 0.29px;
  a {
    color: #FFF;
    text-decoration: none;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
  strong {
    font-weight: 500;
  }
  p.first, p.second {
    display: inline;
  }
  a.intro {
    display: none;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

@media (max-width: 576px) {
  header {
    h1 {
      text-align: center;
      float: none;
      line-height: normal;
      margin-bottom: 0;
    }
    .links {
      float: none;
      margin-bottom: 24px;
    }
    .resume {
      display: block;
    }
  }
  section#content {
    font-size: 15px;
    a.intro {
      display: inline;
    }
  }
}
